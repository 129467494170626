import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MessageComponent } from './routes/message/message.component';
import { OrderConfirmationComponent } from './routes/order-confirmation/order-confirmation.component';
import { OrderConsentComponent } from './routes/order-consent/order-consent.component';
import { OrderPaymentComponent } from './routes/order-payment/order-payment.component';
import { OrderReviewComponent } from './routes/order-review/order-review.component';
import { RecipientAddressComponent } from './routes/recipient-address/recipient-address.component';
import { RecipientDeliveryMethodComponent } from './routes/recipient-delivery-method/recipient-delivery-method.component';
import { RecipientEmailComponent } from './routes/recipient-email/recipient-email.component';
import { RecipientSelectComponent } from './routes/recipient-select/recipient-select.component';
import { RequestorAddressComponent } from './routes/requestor-address/requestor-address.component';
import { RequestorAttendComponent } from './routes/requestor-attend/requestor-attend.component';
import { RequestorIdentificationComponent } from './routes/requestor-identification/requestor-identification.component';
import { routes } from './classes/common-values';
import { SchoolSelectComponent } from './routes/school-select/school-select.component';
import { SchoolWelcomeComponent } from './routes/school-welcome/school-welcome.component';
import { SecurityService } from './services/security/security.service';
import { AuthenticationFailureComponent } from './routes/authentication-failure/auth-failure.component';
import { SchoolFiceCodeComponent } from './routes/school-ficecode/school-ficecode.component';
import { SchoolProfileIDComponent } from './routes/school-profileid/school-profileid.component';
import { OutagePageComponent } from './routes/outage-page/outage-page.component';

const routeConfig: Routes = [
  {
    path: routes.outageMessage,
    component: OutagePageComponent
  },
  {
    path: routes.messageWithReason,
    component: MessageComponent
  },
  {
    path: routes.orderReview,
    component: OrderReviewComponent,
    canActivate: [SecurityService],
    canDeactivate: [SecurityService]
  },
  {
    path: routes.orderConfirmation,
    component: OrderConfirmationComponent,
    canActivate: [SecurityService]
  },
  {
    path: routes.orderConsent,
    component: OrderConsentComponent,
    canActivate: [SecurityService],
    canDeactivate: [SecurityService]
  },
  {
    path: routes.orderPayment,
    component: OrderPaymentComponent,
    canActivate: [SecurityService],
    canDeactivate: [SecurityService]
  },
  {
    path: routes.recipientSelect,
    component: RecipientSelectComponent,
    canActivate: [SecurityService],
    canDeactivate: [SecurityService]
  },
  {
    path: routes.recipientDeliveryMethod,
    component: RecipientDeliveryMethodComponent,
    canActivate: [SecurityService],
    canDeactivate: [SecurityService]
  },
  {
    path: routes.recipientAddress,
    component: RecipientAddressComponent,
    canActivate: [SecurityService],
    canDeactivate: [SecurityService]
  },
  {
    path: routes.recipientEmail,
    component: RecipientEmailComponent,
    canActivate: [SecurityService],
    canDeactivate: [SecurityService]
  },
  {
    path: routes.requestorAddress,
    component: RequestorAddressComponent,
    canActivate: [SecurityService],
    canDeactivate: [SecurityService]
  },
  {
    path: routes.requestorAttend,
    component: RequestorAttendComponent,
    canActivate: [SecurityService],
    canDeactivate: [SecurityService]
  },
  {
    path: routes.requestorIdentification,
    component: RequestorIdentificationComponent,
    canActivate: [SecurityService],
    canDeactivate: [SecurityService]
  },
  {
    path: routes.schoolWithFiceCode,
    component: SchoolFiceCodeComponent
  },
  {
    path: routes.schoolWithProfileID,
    component: SchoolProfileIDComponent
  },
  {
    path: routes.schoolSelect,
    component: SchoolSelectComponent
  },
  {
    path: routes.schoolWelcome,
    component: SchoolWelcomeComponent,
    canActivate: [SecurityService]
  },
  {
    path: routes.authenticationFailure,
    component: AuthenticationFailureComponent,
    canActivate: [SecurityService]
},
  {
    path: '',
    redirectTo: routes.schoolSelect,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: routes.schoolSelect,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routeConfig, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}

<form novalidate>
  <nsc-card>
    <nsc-card-title shoppingCartCount="3">Pending Order Details</nsc-card-title>
    <nsc-card-content>
      <div class="row nsc-row--20 align-items-center">
        <div class="col-auto">
          <button
            mat-raised-button
            id="orderreview_Addrecepient"
            class="nsc-button"
            type="button"
            color="primary"
            [disabled]="content.recipients.length >= values.maxRecipients"
            (click)="addRecipient()">Add Recipient <mat-icon>add_circle_outline</mat-icon></button>
        </div>
        <div class="col" *ngIf="content.recipients.length >= values.maxRecipients">
          <span class="mat-error ">You have reached the maximum number of recipients. Please complete your order.</span>
        </div>
        <div class="col" *ngIf="content.recipients.length === 0">
          <span class="mat-error ">You have not selected any recipients for your transcript(s).</span>
        </div>
      </div>

      <nsc-spacer size="lg"></nsc-spacer>

      <div *ngFor="let recipient of content.recipients; let i = index">
        <nsc-recipient-details
          [recipient]="recipient"
          (editRecipient)="editRecipient(i)"
          (deleteRecipient)="deleteRecipient(i)">
        </nsc-recipient-details>
        <nsc-spacer size="xs" *ngIf="i < content.recipients.length - 1"></nsc-spacer>
        <hr class="nsc-hr" *ngIf="i < content.recipients.length - 1">
        <nsc-spacer size="xs" *ngIf="i < content.recipients.length - 1"></nsc-spacer>
      </div>

      <nsc-spacer size="md"></nsc-spacer>

      <div class="row justify-content-end" *ngIf="content.totalFees > 0">
        <div class="col-auto">
          <div class=" mat-elevation-z1 nsc-order-total">
            Total Fee for Order: <strong>{{ content.totalFees | currency }}</strong>
          </div>
        </div>
      </div>
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="md"></nsc-spacer>

  <div class="nsc-form-actions">
    <button
      mat-stroked-button
      id="orderreview_cancel"
      class="nsc-form-actions__button button-big"
      color="primary"
      type="button"
      (click)="cancel()">Cancel Order</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button
      mat-raised-button
      id="orderreview_checkout"
      class="nsc-form-actions__button nsc-btn__right button-big"
      color="primary"
      type="button"
      [disabled]="content.recipients.length === 0 || values.isRequiredFieldNull"
      (click)="continue()">Checkout <mat-icon aria-hidden="true" *ngIf="(content.recipients.length !== 0 && !values.isRequiredFieldNull)">chevron_right</mat-icon></button>
  </div>
</form>


import {
  catchError,
  distinctUntilChanged,
  debounceTime,
  filter,
  map,
  takeUntil
} from 'rxjs/operators';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';

import { CommonValues } from '../../classes/common-values';
import { DialogTimeoutComponent } from '../../components/dialog-timeout/dialog-timeout.component';
import { TimeoutService } from '../../services/timeout/timeout.service';
import { Version } from '../../interfaces/version.interface';
import { VersionService } from '../../services/version/version.service';
import { StudentAuthorizationService } from '../../services/studentauthservice/studentauth.service';
import { SchoolService } from '../../services/school/school.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AutocompleteOption } from '../../interfaces/autocomplete-option.interface';
import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DataService } from '../../services/data/data.service';
import { PropertyConfigService } from '../../services/property/property-config.service';
import { ApiResponsePropertyConfig } from '../../interfaces/api-response-property-config.interface';
import { datadogRum } from '@datadog/browser-rum';

@Component({
  selector: 'nsc-app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss']
})
export class AppRootComponent implements OnInit {
  @ViewChild('middleSection') middleContentSection: ElementRef;

  isFirstTabbing = true;
  dialogWarning;
  route: string;
  sssRequestCheck: boolean;
  postAuthRequest: any;
  cookieExists: boolean;
  ficeCode: string;
  unsubscribe$ = new Subject();
  timeArr: any;
  school: any;
  values = {
    schools$: new BehaviorSubject(<AutocompleteOption[]>[]) // complete list for autocomplete to filter down;
  };
  accesscode: string;
  authorizationId: string;
  authTimestamp: number;
  authTimestampString: string;
  version: string;

  constructor(
    private commonValues: CommonValues,
    private dialog: MatDialog,
    private location: Location,
    private router: Router,
    private timeoutService: TimeoutService,
    private versionService: VersionService,
    private studentAuthService: StudentAuthorizationService,
    private schoolService: SchoolService,
    private blockingIndicatorService: BlockingIndicatorService,
    private dataService: DataService,
    private propertyConfigService: PropertyConfigService
  ) { }

  ngOnInit() {
   
    // display the version information for this build;
    this.initServiceValues();
    this.versionService.get().subscribe((json: Version) => {
      this.version =json.v;
      console.log('build:', json.b);
      console.log('version:', json.v);
      console.log('timestamp:', json.t);
    });

    // Check if the user is authenticated to Welcome Page with the ficeCode
    this.isUserAuthenticatedtoWelcomePage();

    // In case if the route from backend is not root, school/select or school/redirect route it to Temporary service interuption
    const path = window.location.pathname;
    if (!(path === '/' || path.indexOf(this.commonValues.routes.schoolRedirectficeCode) !== -1 || path.indexOf(this.commonValues.routes.schoolSelect) !== -1
      || path.indexOf(this.commonValues.routes.schoolRedirectprofileid) !== -1)) {
      this.router.navigate([this.commonValues.routes.messageError], this.commonValues.routes.extras);
    }

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
     // define the routes where the timer shouldn't start;
      const routesNoTimeout = [
        this.commonValues.routes.messageError,
        this.commonValues.routes.messageTimeout
      ];

      // update the route so the header can display the proper data in the proper layout;
      this.route = this.location.path().replace('/', '');

      // setting the focus on the body when user lands on welcome page 
      if(this.commonValues.routes.schoolWelcome.indexOf(this.route) > -1){
        (document.querySelector('.nsc-app-root') as HTMLElement).focus();
      }
      // set the timeout on route change, so we dont have to set it on every route init function;
      // if the user isn't on the first page of the app;
      if (routesNoTimeout.indexOf(this.route) === -1) {
        this.timeoutService.startWarningCountdown();
      }
      
    });

    this.timeoutService.showWarning.subscribe((showDialog: boolean) => {
      if (showDialog) {
        this.displayWarning();
      } else if (this.dialogWarning) {
        this.dialogWarning.close();
      }
    });
	
    datadogRum.init({
      applicationId: '1109d714-d419-4c4b-87aa-066fbc4432f5',
      clientToken: 'pubd895475e4c2fc9ecace03acad6585925',
      site: 'datadoghq.com',
      service: 'transcriptservicesuiapp',
      env: this.getEnv(),
      // Specify a version number to identify the deployed version of your application in Datadog 
       version: this.version,
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
  }
  
  isUserAuthenticatedtoWelcomePage() {
    /*    Call the Post Authorize API to check if the user is Authenticated
    *     Route it to school Welcome
    */
    this.generateAuthRequest();
    this.studentAuthService.postAuthorize(this.postAuthRequest).subscribe(
      (response: any) => {
        if (response) {
          this.dataService.data.sssRequestCheck = true;
          this.dataService.postAuthorizationData = response;
          this.ficeCode = response.ficeCode;
          if (this.ficeCode) {
            this.routeSchoolWelcome();
          } else {
            this.dataService.data.sssRequestCheck = false;
          }
        }
        else {
          this.dataService.data.sssRequestCheck = false;
        }
      },
      (err: HttpErrorResponse) => {
        return this.router.navigate([this.commonValues.routes.authenticationFailure]);
      }
    );
  }
  routeSchoolWelcome(): void {
    /*   Route it to School Welcome Page based on ficeCode
    */
    this.blockingIndicatorService.open();
    this.schoolService
      .get(this.ficeCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        window.setTimeout(() => {
          this.router.navigate([this.commonValues.routes.schoolWelcome]);
        }, this.commonValues.loading.delay);
      });
  }
  // on route change, scroll to top of page;
  // add a setTimeout so it'll run after the blocking indicator has been removed;
  onDeactivate() {
    window.setTimeout(() => window.scrollTo(0, 0));
  }
  // Generate empty auth request
  generateAuthRequest(): void {
    const authRequest: any = {
      'accessCode': this.accesscode,
      'authorizationId': this.authorizationId,
      'authTimestamp': this.authTimestamp
    };
    this.postAuthRequest = authRequest;
  }
  initServiceValues(): void {
    this.propertyConfigService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: ApiResponsePropertyConfig) => {
        if (json) {
          this.dataService.save({
            propertyConfig: json
          });
        }
      });
  }
  displayWarning(): void {
    // close any existing warning dialog before showing a new one;
    if (this.dialogWarning) {
      this.dialogWarning.close();
    }

    // show the new warning dialog;
    this.dialogWarning = this.dialog.open(DialogTimeoutComponent, {
      disableClose: true,
      width: '480px',
      ariaLabelledBy: "modalSessionTimeoutTitle",
      ariaDescribedBy: "modalSessionTimeoutContent",
      autoFocus:false,
      data:{
        title:'Session Timeout',
        id:'modalSessionTimeout'
      }
    });
  }

  // setting the focus for the current elemnt. for the screen reader after "skip to main Content" link is pressed
  // first element was not read.
  removeFocus(idContent: string) {
    // if is first time tab after the focus is in the main content
    if (this.isFirstTabbing && document.activeElement.id === idContent) {
      this.isFirstTabbing = false;
      setTimeout(() => {
        if (this.route !== this.commonValues.routes.schoolSelect) {
          // getting the current child element active
          const active_el = this.middleContentSection.nativeElement.ownerDocument.activeElement;
          // gettting aria-labelledby attr since that is the attr attached by default in the html
          const txt_active_el = active_el.getAttribute('aria-labelledby');
          const lbl_attr = active_el.getAttribute('aria-label');
          // for the breadcrumbs the text displayed is not the same that the screen reader reads, that is the reason that we are taking the value instead of the textContent
          const txt = (txt_active_el === null || txt_active_el === 'undefined') ? active_el.textContent : txt_active_el;

          if (lbl_attr) {
            active_el.removeAttribute('aria-label');
            active_el.setAttribute('aria-label', (txt.replace(/\s+$/, '')));
          }
          else {
            // adding space to the text, so the screen reader can detect the change
            active_el.setAttribute('aria-label', txt + ' ');
          }
          active_el.setAttribute('aria-live', 'polite');
        }
      }, 0);
    }
  }


  // event triggered from skip-to-main-content (headerComponent) link
  // for screen reader we need to read content from top left to bottom
  skipToMainContentCall() {
    this.isFirstTabbing = true;
    const middleSection = this.middleContentSection.nativeElement as HTMLDivElement;
    middleSection.setAttribute('tabIndex', '-1');
    middleSection.focus();
  }

  public getEnv(): string {
    const currentUrl = document.location.origin;
           if (currentUrl.includes('local')) {
            return 'local'; 
        } else if (currentUrl.includes('dev') || currentUrl.includes('local')) {
            return 'dev';
        } else if (currentUrl.includes('qa')) {
            return 'qa';
        } else if (currentUrl.includes('demo')) {
            return 'stg';
        } else {
            return 'prod';
        }
    }
}
